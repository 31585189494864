import { mapActions, mapGetters } from 'vuex';
const userFSDocumentMixin = {
  // I removed the created hook because it is sometimes fired after trying to use the userFSDocument properties
  data() {
    return {
      isLoadingUserDocument: false,
    };
  },
  methods: {
    ...mapActions('AuthModule', ['fetchUserFSDocument']),
    async fetchUser() {
      this.isLoadingUserDocument = true;
      try {
        await this.fetchUserFSDocument();
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoadingUserDocument = false;
      }
    },
  },
  computed: {
    ...mapGetters('AuthModule', ['getCurrentUserFromFirebaseAuth', 'userFSDocument']),
  },
};

export default userFSDocumentMixin;
