<template>
  <div>
    <AppBar />

    <div class="pa-6 pt-0">
      <v-row
        class="mb-10"
        no-gutters>
        <h1>SETTINGS</h1>
      </v-row>

      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="offWhite"
          class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
          block
          @click="goTo('ChangeName')">
          <span class="reduce-letter-spacing brandGrey1--text">Change name</span>
          <v-icon color="primaryColor">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>

      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="offWhite"
          class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
          block
          @click="goTo('ChangeEmail')">
          <span class="reduce-letter-spacing brandGrey1--text">Update email address</span>
          <v-icon color="primaryColor">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>

      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="offWhite"
          class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
          block>
          <span class="reduce-letter-spacing brandGrey1--text">Account status</span>
          <span class="light-green"
            ><span
              id="dot"
              class="d-inline-block mr-1"
              >&#x2022;</span
            >
            active</span
          >
        </v-btn>
      </v-row>

      <v-row
        class="mb-4"
        style="width: 100%"
        no-gutters>
        <v-btn
          elevation="0"
          color="offWhite"
          class="text-none text-left d-flex justify-space-between px-5 py-8 rounded-xl"
          block>
          <div class="text reduce-letter-spacing">
            <p
              id="invite"
              :style="{ color: brandGrey2, fontSize: '.8rem' }">
              Invite your colleagues
            </p>
            <p class="font-weight-bold py-0">{{ trimLink(inviteLink) }}</p>
          </div>

          <v-btn
            text
            :disabled="isFetchingOrganization"
            id="copy"
            class="light-green pa-0"
            @click="copyInviteLink"
            >COPY</v-btn
          >
        </v-btn>
      </v-row>

      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="offWhite"
          class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
          @click="goTo('EmailPreferences')"
          block>
          <span class="reduce-letter-spacing brandGrey1--text">Email preferences</span>
          <v-icon color="primaryColor">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>
      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="offWhite"
          class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
          @click="openFAQs"
          block>
          <span class="reduce-letter-spacing brandGrey1--text">Help and FAQs</span>
          <v-icon color="primaryColor">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>
      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="offWhite"
          class="text-none d-flex justify-space-between px-5 py-8 rounded-xl"
          @click="openTermsAndConditions"
          block>
          <span class="reduce-letter-spacing brandGrey1--text">Terms and Conditions</span>
          <v-icon color="primaryColor">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>
      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="primaryColor"
          outlined
          class="pa-6"
          rounded
          block
          :href="`mailto:${email}`">
          CONTACT US
        </v-btn>
      </v-row>
      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="primaryColor"
          outlined
          class="py-6"
          rounded
          block
          @click="logout">
          Sign out
        </v-btn>
      </v-row>
      <v-row
        class="mb-4"
        no-gutters>
        <v-btn
          elevation="0"
          color="errorDark"
          outlined
          class="pa-6"
          rounded
          block
          @click="requestAccountDeletion">
          REQUEST ACCOUNT DELETION
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
  import SettingsMixin from '@/views/profile/settings/settings/Settings.mixin.vue';
  export default {
    mixins: [SettingsMixin],
  };
</script>

<style scoped>
  h1 {
    letter-spacing: 0.2rem;
  }
  .light-green {
    color: var(--v-primaryColor-base);
    background-color: transparent !important;
  }
  #dot {
    transform: translateY(-3px) scale(3.5);
  }
  #invite {
    font-size: 0.8rem;
    transform: translateY(8px);
  }
  #copy {
    transform: translate(10px, 8px);
  }
  .reduce-letter-spacing {
    letter-spacing: 0.01rem;
  }
</style>
