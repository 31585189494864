<script>
  import userFSDocumentMixin from '@/mixins/userFSDocumentMixin.js';
  import { mapMutations, mapActions, mapGetters } from 'vuex';
  import AppBar from '@/components/general/app-bar/AppBar.vue';

  export default {
    mixins: [userFSDocumentMixin],
    name: 'settings',
    data() {
      return {
        inviteLink: 'Fetching invite link...',
        isFetchingOrganization: false,
        email: 'ondemand@weareluminate.co',
      };
    },
    components: {
      AppBar,
    },
    computed: {
      ...mapGetters('AuthModule', ['getUserOrganizationId']),
      brandGrey2() {
        return this.$vuetify.theme.themes.light.brandGrey2;
      },
    },
    async created() {
      await this.fetchUser();
      await this.setOrganizationName();
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapActions('OrganizationsModule', { fetchUserOrganizaion: 'fetchOne' }),
      goTo(name) {
        this.$router.push({
          name,
        });
      },
      openFAQs() {
        window.location.href = 'https://weareluminate.co/luminate-on-demand/ondemand-faqs/';
      },
      openTermsAndConditions() {
        window.location.href = 'https://weareluminate.co/app-terms-conditions/';
      },
      async setOrganizationName() {
        this.isFetchingOrganization = true;
        try {
          const { data } = await this.fetchUserOrganizaion(this.getUserOrganizationId);
          this.inviteLink = data.inviteLink;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isFetchingOrganization = false;
        }
      },
      async setInviteLink() {
        this.isFetchingInviteLink = true;

        try {
          const { data } = await this.fetchInviteLink(this.getUserOrganizationId);
          this.inviteLink = data;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isFetchingInviteLink = false;
        }
      },
      copyInviteLink() {
        navigator.clipboard.writeText(this.inviteLink);
        this.setSuccessSnackbarMessage('Copied to clipboard!');
      },
      requestAccountDeletion() {
        window.location.href = `mailto:${this.email}?subject=Account deletion request&body=Hello My name is ${this.userFSDocument.name} and my ID is ${this.userFSDocument.uid}, I would like to rquest my account deletion. Thank you!`;
      },
      logout() {
        this.$router.push({
          name: 'Logout',
        });
      },
      trimLink(link) {
        return link.length < 28 ? link : link.slice(0, 27);
      },
    },
  };
</script>
